<template>
  <AppSidebar fixed>
    <SidebarHeader/>
    <SidebarForm/>
    <SidebarNav :navItems="nav"></SidebarNav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </AppSidebar>
</template>
<script>
import { SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav } from '@coreui/vue'

export default {
  name: 'Sidebar',
  components: {
    AppSidebar,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {}
  },
  computed: {
    name () {
      return this.$route.name
    },
    nav () {
      const navItems = []

      // Subscription
      if (this.$can('view', 'subscription')) {
        navItems.push({ title: true, name: 'Subscription' })
      }

      // Manage Accounts
      if (this.$can('manage', 'billing')) {
        navItems.push({
          name: 'Billing',
          url: '/settings/subscription/billing',
          icon: 'icon-credit-card'
        })
      }

      if (this.$can('view', 'systemSettings')) {
        navItems.push({ title: true, name: 'System' })
      }

      // Manage Accounts
      if (this.$can('manage', 'accounts')) {
        navItems.push({
          name: 'Accounts',
          url: '/settings/accounts',
          icon: 'icon-people'
        })
      }

      // Manage Organizations
      if (this.$can('manage', 'organizations')) {
        navItems.push({
          name: 'Organizations',
          url: '/settings/organizations',
          icon: 'icon-briefcase'
        })
      }

      // Manage Roles
      if (this.$can('manage', 'roles')) {
        navItems.push({
          name: 'Roles',
          url: '/settings/system/roles',
          icon: 'icon-lock'
        })
      }

      // Manage System Settings
      if (this.$can('manage', 'systemSettings')) {
        navItems.push({
          name: 'Settings',
          url: '/settings/system/application',
          icon: 'icon-settings'
        })
      }

      // Templates
      if (this.$can('view', 'templates')) {
        navItems.push({ title: true, name: 'Templates' })
      }

      // Email Templates
      if (this.$can('manage', 'emailTemplates')) {
        navItems.push({
          name: 'Email',
          url: '/settings/templates/email',
          icon: 'icon-envelope-open'
        })
      }

      // Integrations
      if (this.$can('view', 'integrations')) {
        navItems.push({ title: true, name: 'Integrations' })
      }

      // Mailgun Integration
      if (this.$can('manage', 'mailgun')) {
        navItems.push({
          name: 'Mailgun',
          url: '/settings/integrations/mailgun',
          icon: 'icon-paper-plane'
        })
      }

      // CardConnect Integration
      if (this.$can('manage', 'cardConnect')) {
        navItems.push({
          name: 'Card Connect',
          url: '/settings/integrations/cardConnect',
          icon: 'icon-credit-card'
        })
      }

      return navItems
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  }
}
</script>
